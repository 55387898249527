
.section {
    margin-bottom: 1rem;
}



.section h1, .section .box {
    transition: color 0.75s ease;
}

.faded h1, .faded .box{
    color: #dbdbdb;
}

.invite__main-header.title {
    font-size: 2.3rem;
    margin-bottom: 2rem;
}

.invite__secondary-header.title {
    font-size: 2.1rem;
}

.invite__ternary-header.title {
    font-size: 1.8rem;
    color: #575757;
}

.invite__position.title {
    font-size: 1.8rem;
}